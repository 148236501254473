.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-logo {
  width: auto !important;
  display: block;
  margin: auto;
  width: 80% !important;
  height: auto;
}

input[type="range"] {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-x {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.small-margin-top {
  margin-top: 14px !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.top-buffer {
  margin-top: 20px !important;
}

.bottom-buffer {
  margin-bottom: 20px !important;
}

.center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.max-height {
  height: 100%;
}

.user-link {
  color: black;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.pinzone {
  border: dashed 1px;
  border-color: #808080;
  padding: 10px;
  height: auto;
  border-radius: 5px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.username {
  padding: 10px;
}

.user-popup {
  max-height: 600px;
  overflow-y: scroll;
}

.clickable {
  cursor: pointer;
  color: #000080;
}

.full-width {
  max-width: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-bar {
  overflow-x: auto;
  box-sizing: content-box;
}

.successField {
  color: #2c662d !important;
  border-color: #2c662d !important;
}

.errorText {
  color: #9f3a38 !important;
}

.full-search-bar {
  width: 100% !important;
}

.full-search-bar .input {
  width: 100% !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.slide {
  cursor: pointer;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 20px;
}

.filepond--credits {
  display: none;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 200px;
}

.char-count {
  color: "grey";
  text-align: right;
}

.break-word {
  word-wrap: break-word;
}
